import React, { Suspense, lazy, useEffect } from 'react';
import { Spin, notification } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';
import Drawer from './comps/Drawer';
import Modal from './comps/Modal';
import ConfirmModal from './comps/ConfirmModal';
import TenantService from './../../config/dataService/tenantService';
import keys from './../../utility/keys';
import { getItem } from './../../utility/localStorageControl';
import { toggleLoading, setNewTenant, setNewData } from './../../redux/tenant/actionCreator';
import { showModal } from './../../redux/modal/actionCreator';
import TenantSelector from './comps/TenantSelector';
import strings from '../../utility/strings';
import pages from '../../config/pages';
import { useDispatch } from 'react-redux';
//const SIPTemplate = lazy(() => import('./SIPTemplate'));
const Resellers = lazy(() => import('./Resellers'));

const Customers = lazy(() => import('./Customers'));
const Documents = lazy(() => import('./Document'));
const SIPTemplate = lazy(() => import('./SIPTemplate'));
const Incall = lazy(() => import('./Incall'));
const Ingresses = lazy(() => import('./Ingresses'));
const Meetings = lazy(() => import('./Meetings'));

const OutCall = lazy(() => import('./OutCall'));
const Voicemail = lazy(() => import('./Voicemail'));
const Users = lazy(() => import('./Users'));
const SipTransport = lazy(() => import('./SIPTransport'));
const Skills = lazy(() => import('./Skill'));
const SkillRules = lazy(() => import('./SkillRules'));
const Agents = lazy(() => import('./Agents'));
const Musics = lazy(() => import('./Musics'));
const SoundFiles = lazy(() => import('./SoundFiles'));
// const ProviderManagement = lazy(() => import('./ProviderManagement'));
const Lines = lazy(() => import('./Lines'));
const Conferences = lazy(() => import('./Conference'));
const Callpermissions = lazy(() => import('./Callpermissions'));
const CallPickups = lazy(() => import('./CallPickups'));
const Schedule = lazy(() => import('./Schedule'));
const BsFilters = lazy(() => import('./BsFilters'));
const RingGroups = lazy(() => import('./RingGroups'));
// const GlobalSettings = lazy(() => import('./GlobalSettings'));
// const DevicePlugin = lazy(() => import('./GlobalSettings/Comps/DevicesPlugin'));
// const ProvisioningRegistrars = lazy(() => import('./GlobalSettings/Comps/ProvisioningRegistrars'));
// const FileSystem = lazy(() => import('./GlobalSettings/Comps/FilesSystem'));
// const Tenants = lazy(() => import('./GlobalSettings/Comps/Tenants'));
// const ProvisioningConfifDevice = lazy(() => import('./GlobalSettings/Comps/ProvisioningConfifDevice'));
// const FeatureAccess = lazy(() => import('./GlobalSettings/Comps/FeatureAccess'));
// const PjsipTransport = lazy(() => import('./GlobalSettings/Comps/PjsipTransport'));
const VisualBuilder = lazy(() => import('./VisualBuilder'));
const IVR = lazy(() => import('./IVR'));
const Sessions = lazy(() => import('./Sessions'));
const TotalCDR = lazy(() => import('./TotalCDR'));
const AgentCDR = lazy(() => import('./AgentCDR'));
const License = lazy(() => import('./license'));
const LicenseType = lazy(() => import('./licenseType'));
const LicenseCategory = lazy(() => import('./licenseCategory'));
// const AuthUsers = lazy(() => import('./AuthUsers'));
const Extensions = lazy(() => import('./Extensions'));
const Switchboards = lazy(() => import('./Switchboards'));
const Applications = lazy(() => import('./Applications'));
const Queues = lazy(() => import('./Queues'));
const Trunks = lazy(() => import('./Trunk'));
const ParkingLots = lazy(() => import('./ParkingLots'));
const Pagings = lazy(() => import('./Pagings'));
const PhoneBookConfigurations = lazy(() => import('./PhoneBookConfigurations'));
const PhoneBookContacts = lazy(() => import('./PhoneBookContacts'));
// const DirectorySources = lazy(() => import('./DirectorySources'));
// const DirectoryProfiles = lazy(() => import('./DirectoryProfiles'));
const Webhooks = lazy(() => import('./Webhooks'));
const WebhookLogs = lazy(() => import('./WebhookLogs'));
// const ExtensionFeatures = lazy(() => import('./GlobalSettings/Comps/ExtensionsFeatures'));
// const Plugins = lazy(() => import('./GlobalSettings/Comps/Plugins'));
const Funckeys = lazy(() => import('./Funckeys'));
const Dashboard = lazy(() => import('./dashboard'));
const Tenant = lazy(() => import('./Tenant'));
// const Context = lazy(() => import('./Context'));
// const Policies = lazy(() => import('./Policies'));
// const Groups = lazy(() => import('./Groups'));

// const PhoneServiceProvider = lazy(() => import('./PhoneServiceProviders'));
const BlackLists = lazy(() => import('./BlackList'));
const Contacts = lazy(() => import('./Contacts'));
const Addresses = lazy(() => import('./Addresses'));
const Wizards = lazy(() => import('./Wizards'));
const ContextWizard = lazy(() => import('./Wizards/context'));
const IncallWizard = lazy(() => import('./Wizards/incall'));
const OutcallWizard = lazy(() => import('./Wizards/outcall'));

const TrunkWizard = lazy(() => import('./Wizards/trunk'));
const IvrWizard = lazy(() => import('./Wizards/ivr'));

const QueuesWizard = lazy(() => import('./Wizards/queues'));

const Tabs = lazy(() => import('./Wizards/Tabs'));

// import QueueCDR from './QueueCDR';

// const ResellerDocument = () => <Documents role="reseller" />;
// const CustomerDocument = () => <Documents role="customer" />;
const ResellerContacts = () => <Contacts role="reseller" />;
const CustomerContacts = () => <Contacts role="customer" />;
const ResellerAddresses = () => <Addresses role="reseller" />;
const CustomerAddresses = () => <Addresses role="customer" />;
const ContactAddresses = () => <Addresses role="contact" />;

const Admin = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const getTenants = async () => {
    dispatch(toggleLoading(true));
    let call = await TenantService.getAll({ limit: 1000, offset: 0, version: 1 });
    dispatch(toggleLoading(false));
    if (!call.items) {
      notification['error']({
        message: strings().operationFailed,
      });
      return;
    }
    dispatch(toggleLoading(false));
    if (call.items.length) dispatch(setNewData(call.items));
  };

  useEffect(() => {
    getTenants();
    const showTenantSelectorModal = () => {
      dispatch(
        showModal({
          title: strings().tenant.selectTenant,
          children: TenantSelector,
          closable: false,
        }),
      );
    };
    const t = getItem(keys.tenant);
    try {
      if (t) dispatch(setNewTenant(t));
      else showTenantSelectorModal();
    } catch (e) {
      showTenantSelectorModal();
    }
  }, []);

  return (
    <>
      <Switch>
        <Suspense
          fallback={
            <div className="spin">
              <Spin />
            </div>
          }
        >
          <Route path={`${path}${pages.webhookLogs}/:subscriptionUUID`} component={WebhookLogs} />
          <Route path={`${path}${pages.dashboard}`} component={getItem(keys.tenant) ? TotalCDR : null} />
          <Route path={`${path}${pages.wizard}`} component={Wizards} />
          <Route path={`${path}${pages.contextWizard}`} component={ContextWizard} />
          <Route path={`${path}${pages.incallWizard}`} component={IncallWizard} />
          <Route path={`${path}${pages.outcallWizard}`} component={OutcallWizard} />
          <Route path={`${path}${pages.trunkWizard}`} component={TrunkWizard} />
          <Route path={`${path}${pages.queuesWizard}`} component={QueuesWizard} />
          <Route path={`${path}${pages.ivrWizard}`} component={IvrWizard} />
          <Route path={`${path}${pages.wizardTabs}`} component={Tabs} />

          {/* <Route path={`${path}${pages.phoneServiceProviders}`} component={PhoneServiceProvider} /> */}
          <Route path={`${path}${pages.tenant}`} component={Tenant} />
          {/* <Route path={`${path}${pages.context}`} component={Context} /> */}
          {/* <Route path={`${path}${pages.policies}`} component={Policies} /> */}
          {/* <Route path={`${path}${pages.groups}`} component={Groups} /> */}

          {/* 
          <Route path={`${path}${pages.sipTemplate}`} component={SIPTemplate} />
          <Route path={`${path}${pages.sipTransport}`} component={SipTransport} /> */}
          <Route path={`${path}${pages.incalls}`} component={Incall} />

          <Route path={`${path}${pages.outcalls}`} component={OutCall} />
          <Route path={`${path}${pages.voicemails}`} component={Voicemail} />
          <Route path={`${path}${pages.skills}`} component={Skills} />
          <Route path={`${path}${pages.skillrules}`} component={SkillRules} />
          <Route path={`${path}${pages.agents}`} component={Agents} />
          <Route path={`${path}${pages.musics}`} component={Musics} />
          <Route path={`${path}${pages.users}`} component={Users} />
          {/* <Route path={`${path}${pages.authUsers}`} component={AuthUsers} /> */}
          {/* <Route path={`${path}${pages.providerManagement}`} component={ProviderManagement} /> */}
          <Route path={`${path}${pages.soundfiles}`} component={SoundFiles} />
          <Route path={`${path}${pages.lines}`} component={Lines} />
          <Route path={`${path}${pages.conferences}`} component={Conferences} />
          <Route path={`${path}${pages.callPermissions}`} component={Callpermissions} />
          <Route path={`${path}${pages.callPickups}`} component={CallPickups} />
          <Route path={`${path}${pages.schedules}`} component={Schedule} />
          <Route path={`${path}${pages.bSFilters}`} component={BsFilters} />
          <Route path={`${path}${pages.ringGroups}`} component={RingGroups} />
          <Route path={`${path}${pages.ingresses}`} component={Ingresses} />
          <Route path={`${path}${pages.meetings}`} component={Meetings} />

          {/* <Route path={`${path}/${pages.globalSettings}`} component={GlobalSettings} /> */}
          {/* <Route path={`${path}/${pages.devicePlugin}`} component={DevicePlugin} /> */}
          {/* <Route path={`${path}/${pages.provisioningRegistrars}`} component={ProvisioningRegistrars} /> */}
          {/* <Route path={`${path}/${pages.soundFilesSystem}`} component={FileSystem} /> */}
          {/* <Route path={`${path}${pages.tenants}`} component={Tenants} /> */}
          {/* <Route path={`${path}/${pages.provisioningConfifDevice}`} component={ProvisioningConfifDevice} /> */}
          {/* <Route path={`${path}/${pages.featureAccess}`} component={FeatureAccess} /> */}
          {/* <Route path={`${path}/${pages.pjsipTransport}`} component={PjsipTransport} /> */}
          <Route path={`${path}${pages.visualBuilder}`} component={VisualBuilder} />
          <Route path={`${path}${pages.ivrs}`} component={IVR} />
          <Route path={`${path}${pages.sessions}`} component={Sessions} />
          <Route path={`${path}${pages.totalCDR}`} component={TotalCDR} />
          <Route path={`${path}${pages.agentsCDR}`} component={AgentCDR} />
          {/* <Route path={`${path}/${pages.queuesCDR}`} component={QueueCDR} /> */}
          <Route path={`${path}${pages.license}`} component={License} />
          <Route path={`${path}${pages.licenseType}`} component={LicenseType} />
          <Route path={`${path}${pages.licenseCategory}`} component={LicenseCategory} />
          <Route path={`${path}${pages.extensions}`} component={Extensions} />
          <Route path={`${path}${pages.switchboards}`} component={Switchboards} />
          <Route path={`${path}${pages.applications}`} component={Applications} />
          <Route path={`${path}${pages.queues}`} component={Queues} />
          <Route path={`${path}${pages.trunk}`} component={Trunks} />
          <Route path={`${path}${pages.parkingLots}`} component={ParkingLots} />
          <Route path={`${path}${pages.pagings}`} component={Pagings} />
          <Route path={`${path}${pages.phonebookConfigurations}`} component={PhoneBookConfigurations} />
          <Route path={`${path}${pages.phonebookContacts}`} component={PhoneBookContacts} />
          {/* <Route path={`${path}${pages.directorySources}`} component={DirectorySources} />
          <Route path={`${path}${pages.directoryProfile}`} component={DirectoryProfiles} /> */}
          <Route path={`${path}${pages.webhooks}`} component={Webhooks} />
          {/* <Route path={`${path}/${pages.extensionFeatures}`} component={ExtensionFeatures} /> */}
          {/* <Route path={`${path}/${pages.plugins}`} component={Plugins} /> */}
          <Route path={`${path}${pages.funckeys}`} component={Funckeys} />
          <Route path={`${path}${pages.blackList}`} component={BlackLists} />
          {/* <Route path={`${path}/${pages.resellerDocuments}`} component={ResellerDocument} /> */}
          {/* <Route path={`${path}/${pages.customerDocuments}`} component={CustomerDocument} /> */}
          <Route path={`${path}${pages.resellers}`} component={Resellers} />
          <Route path={`${path}${pages.customers}`} component={Customers} />
          <Route path={`${path}${pages.resellerContacts}`} component={ResellerContacts} />
          <Route path={`${path}${pages.customerContacts}`} component={CustomerContacts} />
          <Route path={`${path}${pages.resellerAddresses}`} component={ResellerAddresses} />
          <Route path={`${path}${pages.customerAddresses}`} component={CustomerAddresses} />
          <Route path={`${path}${pages.contactAddresses}`} component={ContactAddresses} />
        </Suspense>
      </Switch>
      <Drawer />
      <Modal />
      <ConfirmModal />
    </>
  );
};

export default withAdminLayout(Admin);
