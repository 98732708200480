import React, { useState } from 'react';
import { Select, Alert, Col, Button } from 'antd';
import { RowStyled } from './style';
import { useDispatch, useSelector } from 'react-redux';
import strings from '../../../../utility/strings';
import { setNewTenant } from '../../../../redux/tenant/actionCreator';
import { hideModal } from '../../../../redux/modal/actionCreator';
const { Option } = Select;

const TenantSelector = () => {
    const tenantState = useSelector(s => s.tenant);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        errorMessage: ""
    })
    const onTenantChange = (uuid) => {
        const t = tenantState.tenants.find(x => x.uuid === uuid);
        if (t) dispatch(setNewTenant(t));
    }
    const style = {
        marginBottom: 16
    }
    const closeModal = () => {
        if (tenantState.currentTenant) {
            dispatch(hideModal());
        }
        else setState(p => ({ ...p, errorMessage: strings().validation.required }));

    }
    return <RowStyled gutter={16}>
        <Col span={24} style={style}>
            <Alert
                message={strings().tenant.pleaseSelectTenant}
                description={strings().tenant.selectTenantMessage}
                type="info"
            />
        </Col>
        <Col span={18} className="selector-wrapper" style={style}>
            <Select
                value={tenantState.tenantUUID}
                style={{ width: 180 }}
                onChange={onTenantChange}
                title={strings().tenant.tenant}
                placeholder={strings().loading}
                loading={tenantState.loading}>
                {tenantState.tenants.map((t, idx) => <Option key={idx} value={t.uuid}>{t.name}</Option>)}
            </Select>
            <div hidden={state.errorMessage ? false : true} className="ant-form-item-explain-error color-error">{state.errorMessage}</div>
        </Col>
        <Col span={6} className="actions" style={style}>
            <Button type="primary" size="medium" className="btn-confirm" onClick={closeModal} disabled={tenantState.loading}>
                {strings().button.confirm}
            </Button>
        </Col>
    </RowStyled>;
}
export default TenantSelector;