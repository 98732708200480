import CryptoJS from 'crypto-js';
import strings from './strings';
import { notification } from 'antd';
/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

export { ellipsis };

export const validate = {
  mobileNumber: function(mobNumber) {
    if (isNaN(mobNumber)) return false;
    else if (!new RegExp(/^9\d{9}$/g).test(mobNumber)) return false;
    else return true;
  },
  email: function(email) {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(String(email).toLowerCase());
  },
  isPersianDate: function(date) {
    const reg = /\d{4}\/(0?[1-9]|1[0-2])\/(0?[1-9]|[1-2][0-9]|3[0-1])/g;
    if (!reg.test(date)) return false;
    let arr = date.split('/');
    let y = parseInt(arr[0]),
      m = parseInt(arr[1]),
      d = parseInt(arr[2]);
    if (m > 6 && d > 30) return false;
    else return true;
  },
};

export const decrypt = txt => {
  var bytes = CryptoJS.AES.decrypt(txt, 'kingofday');
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const encrypt = infoStr => CryptoJS.AES.encrypt(infoStr, 'kingofday').toString();

export function commaThousondSeperator(input) {
  if (!input) return input;
  let str = isNaN(input) ? input : input.toString();
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function gregorian_to_jalali(gy, gm, gd) {
  let g_d_m = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
  let jy;
  if (gy > 1600) {
    jy = 979;
    gy -= 1600;
  } else {
    jy = 0;
    gy -= 621;
  }
  let gy2 = gm > 2 ? gy + 1 : gy;
  let days =
    365 * gy +
    parseInt((gy2 + 3) / 4) -
    parseInt((gy2 + 99) / 100) +
    parseInt((gy2 + 399) / 400) -
    80 +
    gd +
    g_d_m[gm - 1];
  jy += 33 * parseInt(days / 12053);
  days %= 12053;
  jy += 4 * parseInt(days / 1461);
  days %= 1461;
  if (days > 365) {
    jy += parseInt((days - 1) / 365);
    days = (days - 1) % 365;
  }
  let jm = days < 186 ? 1 + parseInt(days / 31) : 7 + parseInt((days - 186) / 30);
  let jd = 1 + (days < 186 ? days % 31 : (days - 186) % 30);
  return [jy, jm, jd];
}

export const jalali_to_gregorian = function(jy, jm, jd) {
  let gy;
  if (jy > 979) {
    gy = 1600;
    jy -= 979;
  } else {
    gy = 621;
  }
  let days =
    365 * jy +
    parseInt(jy / 33) * 8 +
    parseInt(((jy % 33) + 3) / 4) +
    78 +
    jd +
    (jm < 7 ? (jm - 1) * 31 : (jm - 7) * 30 + 186);
  gy += 400 * parseInt(days / 146097);
  days %= 146097;
  if (days > 36524) {
    gy += 100 * parseInt(--days / 36524);
    days %= 36524;
    if (days >= 365) days++;
  }
  gy += 4 * parseInt(days / 1461);
  days %= 1461;
  if (days > 365) {
    gy += parseInt((days - 1) / 365);
    days = (days - 1) % 365;
  }
  let gd = days + 1;
  let sal_a = [
    0,
    31,
    (gy % 4 == 0 && gy % 100 != 0) || gy % 400 == 0 ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];
  let v, gm;
  for (gm = 0; gm < 13; gm++) {
    v = sal_a[gm];
    if (gd <= v) break;
    gd -= v;
  }
  return [gy, gm, gd];
};

export const toPersianDate = function(dt) {
  var resArr = gregorian_to_jalali(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
  var res = resArr[0] + '/';
  if (resArr[1] < 10) res += '0' + resArr[1] + '/';
  else res += resArr[1] + '/';
  if (resArr[2] < 10) res += '0' + resArr[2];
  else res += resArr[2];
  return res;
};

export const shortText = (text, length, appender) => {
  let textLength = text.length;
  if (textLength > length) return text.substring(0, length) + (appender || '...');
  return text;
};

export const convertMomentToDate = mDt => {
  let y = mDt.jYear(),
    m = mDt.jMonth() + 1,
    d = mDt.jDate();
  return `${y}/${m < 10 ? '0' + m : m}/${d < 10 ? '0' + d : d}`;
};

export const convertToEn = function(string) {
  return string
    .replace(/[\u0660-\u0669]/g, function(c) {
      return c.charCodeAt(0) - 0x0660;
    })
    .replace(/[\u06f0-\u06f9]/g, function(c) {
      return c.charCodeAt(0) - 0x06f0;
    });
};

export const copyToClipboard = text => {
  const range = document.createRange();
  const selection = document.getSelection();
  const mark = document.createElement('span');
  mark.textContent = text;
  // reset user styles for span element
  mark.style.all = 'unset';
  // prevents scrolling to the end of the page
  mark.style.position = 'fixed';
  mark.style.top = 0;
  mark.style.clip = 'rect(0, 0, 0, 0)';
  // used to preserve spaces and line breaks
  mark.style.whiteSpace = 'pre';
  // do not inherit user-select (it may be `none`)
  mark.style.webkitUserSelect = 'text';
  mark.style.MozUserSelect = 'text';
  mark.style.msUserSelect = 'text';
  mark.style.userSelect = 'text';
  mark.addEventListener('copy', function(e) {
    e.stopPropagation();
  });

  document.body.appendChild(mark);

  // The following line is very important
  if (selection.rangeCount > 0) {
    selection.removeAllRanges();
  }

  range.selectNodeContents(mark);
  selection.addRange(range);
  document.execCommand('copy');
  document.body.removeChild(mark);
};

export const convertToQueryString = obj => new URLSearchParams(obj).toString();

export const sortAsc = function(key) {
  return function(a, b) {
    if (a[key] === b[key]) return 0;
    else if (a[key] > b[key]) return 1;
    else return -1;
  };
};
export const sortDesc = function(key) {
  return function(a, b) {
    if (a[key] === b[key]) return 0;
    else if (a[key] > b[key]) return -1;
    else return 1;
  };
};
export const getObjectValue = (obj, field) => {
  let arr = field.split('.');
  let value = null;
  for (let i = 0; i < arr.length; i++) {
    if (i === 0) value = obj[arr[i]];
    else value = value[arr[i]];
  }
  return value;
};
export const paginate = (array, pageSize, pageNumber) =>
  array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
export const okNotif = (message, description) => {
  notification['success']({
    message: message ?? strings().successfulOperation,
    description: description ?? '',
    className: description ? '' : 'custom-ant-notification',
    //duration:"99999999"
  });
};
export const failedNotif = (message, description) => {
  notification['error']({
    message: message ?? strings().operationFailed,
    description: description ?? '',
    className: description ? '' : 'custom-ant-notification',
    //duration:"99999999"
  });
};
export const log = (...params) => {
  if (process.env.NODE_ENV === 'development') for (let i = 0; i < params.length; i++) console.log(params[i]);
};

export function generateExtens(context, extenType, extensions) {
  let rangePropNames;
  switch (extenType) {
    case 'conference':
      rangePropNames = ['conference_room_ranges'];
      break;
    case 'group':
      rangePropNames = ['group_ranges'];
      break;
    case 'incall':
      rangePropNames = ['incall_ranges'];
      break;
    case 'queue':
      rangePropNames = ['queue_ranges'];
      break;
    case 'user':
      rangePropNames = ['user_ranges'];
      break;
    default:
      rangePropNames = ['conference_room_ranges', 'group_ranges', 'incall_ranges', 'queue_ranges', 'user_ranges'];
  }

  const reservedExten = extensions
    .filter(extension => extension.context == context.name)
    .map(extensionItem => Number(extensionItem.exten));
  const extens = [];
  for (const propName of rangePropNames)
    for (const range of context[propName])
      for (let i = Number(range.start); i <= Number(range.end); i++) {
        if (reservedExten.includes(i)) continue;

        extens.push(i + '');
      }

  return extens;
}
export function randomString(length, configs = {}) {
  if (configs.uppercase == undefined) configs.uppercase = true;
  if (configs.numbers == undefined) configs.numbers = true;

  let result = '';

  let characters = 'abcdefghijklmnopqrstuvwxyz';
  if (configs.uppercase) characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + characters;
  if (configs.numbers) characters += '0123456789';

  for (let i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * characters.length));

  return result;
}

export function randomNumericString(length) {
  let result = '';
  const characters = '0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export const englishTextValidator = (_, value) => {
  if (!value || /^[A-Za-z ]+$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(strings().wizard.englishValidator));
};
// const emailValidationRule = {
//   validator: (_, value) => {
//     const validationResponse = validate(value, [validators.email(strings().validation.invalidEmail)]);
//     if (!validationResponse.isValid) {
//       return Promise.reject(new Error(validationResponse.message));
//     }
//     return Promise.resolve();
//   },
// };
export const validateEnglishAlphabetAndNumbers = (_, value) => {
  const regex = /^[A-Za-z0-9]*$/;
  if (value && !regex.test(value)) {
    return Promise.reject(new Error(strings().wizard.englishValidator));
  }
  return Promise.resolve();
};

export function isAdmin(acl) {
  return (
    acl.includes('#') ||
    acl.some(permission => {
      // Check if the permission ends with .# and contains only one dot
      const parts = permission.split('.');
      return parts.length === 2 && parts[1] === '#';
    })
  );
}

const arrayDifference = (arr1, arr2) => arr1.filter(item => !arr2.includes(item));

export const handleAssociations = async ({
  currentIds,
  previousIds,
  associateService,
  disassociateService,
  onSuccess,
  onError,
  restore,
  restoreSteps,
}) => {
  const toAssociate = arrayDifference(currentIds, previousIds);
  const toDisassociate = arrayDifference(previousIds, currentIds);

  // Associate new items
  for (const id of toAssociate) {
    const associateResult = await associateService(id);
    if (associateResult) {
      if (restore) restore(restoreSteps);
      if (onError) onError();
      return;
    }
  }

  // Disassociate removed items
  for (const id of toDisassociate) {
    const disassociateResult = await disassociateService(id);
    if (disassociateResult) {
      if (restore) restore(restoreSteps);
      if (onError) onError();
      return;
    }
  }

  if (onSuccess) onSuccess();
};

// Helper function to remove "wazo" from the name
export const removeWazoFromName = name => {
  return name.replace(/wazo_/gi, '').trim();
};
