import { DataService } from './dataService';
import Addresses from './../api';
import TenantService from './tenantService';

export default class UserService {
  static tenantUUID = () => TenantService.getCurrentTenant()?.uuid;

  static async authGetAll(params, headers, configs) {
    return await DataService.get(
      Addresses().users.authGetAll,
      params,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async authGetACL(params, headers, configs) {
    return await DataService.get(
      Addresses().users.authGetACL,
      params,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async authGetUserACL(uuid, params, headers, configs) {
    return await DataService.get(
      Addresses().users.authGetUserACL(uuid),
      params,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async authGetGroups(params, headers, configs) {
    return await DataService.get(
      Addresses().users.authGetGroups,
      params,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async authGetUserGroups(uuid, params, headers, configs) {
    return await DataService.get(
      Addresses().users.authGetUserGroups(uuid),
      params,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async confGetAll(params, headers, configs) {
    return await DataService.get(
      Addresses().users.confGetAll,
      params,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async confGet(id, params, headers, configs) {
    return await DataService.get(
      Addresses().users.confGet(id),
      params,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async authGet(uuid, params, headers, configs) {
    return await DataService.get(Addresses().users.authGet(uuid), params, headers, configs);
  }

  static async getDetails(params, headers, configs) {
    return await DataService.get(
      Addresses().users.getDetails,
      params,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async getRoles(id, params, headers, configs) {
    return await DataService.get(
      Addresses().users.getRoles(id),
      params,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async confirmEmail(params, headers, configs) {
    return await DataService.get(
      Addresses().users.confirmEmail,
      params,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async export(params, headers, configs) {
    return await DataService.get(
      Addresses().users.export,
      params,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async confAdd(data, headers, configs) {
    return await DataService.post(
      Addresses().users.confAdd,
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async authAdd(data, headers, configs) {
    return await DataService.post(
      Addresses().users.authAdd,
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async register(data, headers, configs) {
    return await DataService.post(
      Addresses().users.register,
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async toggleStatus(data, headers, configs) {
    return await DataService.post(
      Addresses().users.toggleStatus,
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async forgotPassword(data, headers, configs) {
    return await DataService.post(
      Addresses().users.forgotPassword,
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async resetPassword(params, headers, configs) {
    return await DataService.get(
      Addresses().users.resetPassword,
      params,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async setUserPassword(user_uuid, data, headers = {}, configs = {}) {
    const url = `${Addresses().users.resetPassword}?user_uuid=${user_uuid}`;
    return await DataService.post(url, data, { 'Wazo-Tenant': UserService.tenantUUID(), ...headers }, configs);
  }
  static async confUpdate(id, data, headers, configs) {
    return await DataService.put(
      Addresses().users.confUpdate(id),
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async authUpdate(uuid, data, headers, configs) {
    return await DataService.put(
      Addresses().users.authUpdate(uuid),
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async updateFallbacks(id, data, headers, configs) {
    return await DataService.put(
      Addresses().users.updateFallbacks(id),
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async updateServices(id, data, headers, configs) {
    return await DataService.put(
      Addresses().users.updateServices(id),
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async updateForwards(id, data, headers, configs) {
    return await DataService.put(
      Addresses().users.updateForwards(id),
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async updateGroups(id, data, headers, configs) {
    return await DataService.put(
      Addresses().users.updateGroups(id),
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async updateRoles(id, data, headers, configs) {
    return await DataService.put(
      Addresses().users.updateRoles(id),
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async confDelete(id, data, headers, configs) {
    return await DataService.delete(
      Addresses().users.confDelete(id),
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async authDelete(uuid, data, headers, configs) {
    return await DataService.delete(
      Addresses().users.authDelete(uuid),
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async updateAuthPassword(uuid, data, headers, configs) {
    return await DataService.put(
      Addresses().users.updateAuthPassword(uuid),
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async updateAuthEmails(uuid, data, headers, configs) {
    return await DataService.put(
      Addresses().users.updateAuthEmails(uuid),
      data,
      { 'Wazo-Tenant': UserService.tenantUUID(), ...headers },
      configs,
    );
  }

  static async getMockoptions() {
    let options = [
      {
        value: '#1',
        id: Math.floor(Math.random() * 100),
      },
      {
        value: '#2',
        id: Math.floor(Math.random() * 100),
      },
    ];
    return new Promise(handler => {
      setTimeout(() => {
        handler(options);
      }, 3000);
    });
  }

  static async getMockoptions(id) {
    return new Promise(handler =>
      setTimeout(() => {
        handler([
          {
            value: '#1',
            id: Math.floor(Math.random() * 100),
          },
          {
            value: '#2',
            id: Math.floor(Math.random() * 100),
          },
        ]);
      }, 3000),
    );
  }
}
